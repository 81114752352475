import React, { Fragment } from 'react';

const TextMention = () => {
  return (
    <Fragment>
      <section className='product-background'>
        <div className='text-mention'>
          <h1 className='product-title'>Mentions Légales</h1>
          <h4 className='text-title'>
            1. Informations relatives à l'hébergeur:
          </h4>
          <p>
            1&1 IONOS SARL 7 place de la Gare - BP 70109 57201 Sarreguemines
            Cedex France Numéro de téléphone : 0970 808 911.
          </p>
          <h4 className='text-title'>2. Informations relatives à l'éditeur:</h4>
          <p>
            Raison sociale : Swell Publicité Enseigne : Swell Publicité Adresse
            : Escale Oceane, 2 impasse de la scierie, 40140 Soustons Téléphone :
            0662147722 E-mail : swellpub@gmail.fr Directeur de publication :
            Jean-Guillaume Dejardin Siret: 79265256200028 Forme juridique : EI
            Auto entrepreneur
          </p>
          <h4 className='text-title'>3. Conditions d'utilisation:</h4>
          <p>
            Le site est accessible par l'url swellpub.fr Son utilisation est
            régie par les présentes conditions générales. En utilisant le site,
            vous reconnaissez avoir pris connaissance de ces conditions et les
            avoir acceptées. Celles-ci pourront être modifiées à tout moment et
            sans préavis par la société SWELL PUBLICITÉ. L'entreprise SWELL
            PUBLICITÉ ne saurait être tenue pour responsable en aucune manière
            d'une mauvaise utilisation du service.
          </p>
          <h4 className='text-title'>4. Limitation de la responsabilité:</h4>
          <p>
            Les informations contenues sur ce site sont aussi précises que
            possible et le site est périodiquement remis à jour, mais peut
            toutefois contenir des inexactitudes, des omissions ou des lacunes.
            Si vous constatez une erreur ou ce qui peut être un
            dysfonctionnement, merci de bien vouloir le signaler par email en
            décrivant le problème de la manière la plus précise possible. Tout
            contenu téléchargé se fait aux risques et périls de l'utilisateur et
            sous sa seule responsabilité. En conséquence, l'entreprise SWELL
            PUBLICITÉ ne saurait être tenue responsable d'un quelconque dommage
            subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de
            données consécutives au téléchargement. Les photos sont non
            contractuelles. Les liens hypertextes mis en place sur ce site
            internet en direction d'autres sites internet ne sauraient engager
            la responsabilité de SWELL PUBLICITÉ.
          </p>
          <h4 className='text-title'>
            6. Droit d'accès, de rectification et de suppression de vos données:
          </h4>
          <p>
            En application de la loi informatique et libertés, les internautes
            disposent d'un droit d'accès, de rectification, de modification et
            de suppression concernant les données qui les concernent
            personnellement. Le droit d'accès : ils peuvent exercer leur droit
            d'accès, pour connaître les données personnelles les concernant, en
            écrivant à l'adresse électronique suivante. Dans ce cas, avant la
            mise en œuvre de ce droit, la Plateforme peut demander une preuve de
            l'identité de l'utilisateur afin d'en vérifier l'exactitude. Le
            droit de rectification : si les données à caractère personnel
            détenues par la Plateforme sont inexactes, ils peuvent demander la
            mise à jour des informations. Le droit de suppression des données :
            les utilisateurs peuvent demander la suppression de leurs données à
            caractère personnel, conformément aux lois applicables en matière de
            protection des données. Ce droit peut être exercé par voie postale
            auprès de SWELL PUBLICITÉ, Escale Oceane, 2 Impasse de la scierie,
            40140 SOUSTONS, France ou par voie électronique à l'adresse email
            suivante : swellpub@gmail.fr Les informations personnelles
            collectées sont confidentielles et ne sont en aucun cas transmises à
            des tiers hormis pour l'éventuelle bonne exécution de la prestation
            commandée par l'internaute. En vertu de la loi n° 2000-719 du 1er
            août 2000, les coordonnées déclarées par l'acheteur pourront être
            communiquées sur réquisition des autorités judiciaires.
          </p>
          <h4 className='text-title'>7. Collecte et protection des données:</h4>
          <p>
            Vos données sont collectées par SWELL PUBLICITÉ. Une donnée à
            caractère personnel désigne toute information concernant une
            personne physique identifiée ou identifiable (personne concernée);
            est réputée identifiable une personne qui peut être identifiée,
            directement ou indirectement, notamment par référence à un nom, un
            numéro d'identification ou à un ou plusieurs éléments spécifiques,
            propres à son identité physique, physiologique, génétique,
            psychique, économique, culturelle ou sociale. Les informations
            personnelles pouvant être recueillies sur le site sont
            principalement utilisées par l'éditeur pour la gestion des relations
            avec vous. A. Les données personnelles collectées dans les
            formulaires de contact sont les suivantes : Nom et prénom Adresse
            mail Numéro de téléphone Afin de traiter votre demande, SWELL
            PUBLICITÉ devra effectuer un traitement des données personnelles
            vous concernant. Les données sont conservées pendant deux (2) ans à
            compter de notre dernier contact et sont destinées exclusivement à
            SWELL PUBLICITÉ, elles ne seront pas transmises à des tiers. B. Les
            données personnelles collectées dans les formulaires d'inscription
            aux newsletters sont les suivantes : Adresse mail Vous aurez le
            choix d'accepter ou non de recevoir : – Des newsletters contenant
            les dernières actualités de la société SWELL PUBLICITÉ; – Des
            publicités adressées par des partenaires commerciaux. En renseignant
            votre email, vous affirmez avoir pris connaissance de nos mentions
            légales. Votre email sera conservé tant que vous ne nous ferez pas
            la demande de suppression de cette donnée. Vous pourrez vous
            désinscrire à tout moment à l'aide des liens de désinscription
            présents sur les communications ou en nous contactant : SWELL
            PUBLICITÉ, Escale Océane, 2 Impasse de la scierie, 40140 SOUSTONS,
            France 0662147722 – swellpub@gmail.com
          </p>
          <h4 className='text-title'>8. Propriété intellectuelle:</h4>
          <p>
            Tout le contenu du présent site, incluant, de façon non limitative,
            les graphismes, images, textes, vidéos, animations, sons, logos,
            gifs et icônes ainsi que leur mise en forme sont la propriété
            exclusive de la société SWELL PUBLICITÉ à l'exception des marques,
            logos ou contenus appartenant à d'autres sociétés partenaires ou
            auteurs. Toute reproduction, distribution, modification, adaptation,
            retransmission ou publication, même partielle, de ces différents
            éléments est strictement interdite sans l'accord express par écrit
            de SWELL PUBLICITÉ.
          </p>
          <h4 className='text-title'>9. Gestion des « cookies »:</h4>
          <p>
            Qu'est-ce qu'un « cookie » ? Un « cookie » ou traceur est un fichier
            électronique déposé sur un terminal (ordinateur, tablette,
            smartphone,...) et lu par exemple lors de la consultation d'un site
            internet, de la lecture d'un courrier électronique, de
            l'installation ou de l'utilisation d'un logiciel ou d'une
            application mobile et ce quel que soit le type de terminal utilisé
            (source : https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi).
            En naviguant sur ce site, des « cookies » émanant de la société
            responsable du site concerné et/ou des sociétés tiers pourront être
            déposés sur votre terminal. Lors de la première navigation sur ce
            site, une fenêtre de gestion du consentement des « cookies »
            apparaîtra. Dès lors, pour poursuivre la navigation sur le site web,
            vous devrez donner au choix soit votre accord ou soit votre
            désaccord. Vous aurez également à disposition dans cet outil de
            consentement, la personnalisation du choix des catégories de «
            cookies » à accepter ou non. Le consentement donné sera stocké sous
            forme de « cookies » fonctionnel dans votre navigateur, valable pour
            une période de trois (3) mois. L'utilisateur a la possibilité de
            modifier son choix à tout moment. Les « cookies » présents sur ce
            site internet se présentent sous trois (3) catégories : A. Cookies
            essentiels Ce site utilise des cookies nécessaires à son bon
            fonctionnement. C'est pour cela qu'ils doivent rester activés. B.
            Cookies de performance Ces cookies nous aident à améliorer votre
            expérience en ligne. Ils ne sont pas essentiels, mais sans eux,
            certaines fonctionnalités peuvent ne pas fonctionner. Ces cookies
            nous permettent de déterminer le nombre de visites et les sources du
            trafic, afin de mesurer et d'améliorer les performances de notre
            site Web. Ils nous aident également à identifier les pages les plus
            / moins visitées et d'évaluer comment les visiteurs naviguent sur le
            site Web. Toutes les informations collectées par ces cookies sont
            agrégées et donc anonymisées. C. Cookies de publicité ciblée Les
            cookies marketing sont généralement utilisés pour vous montrer des
            publicités qui correspondent à vos intérêts. Lorsque vous visitez un
            autre site Web, le cookie de votre navigateur est reconnu et des
            publicités sélectionnées vous sont affichées en fonction des
            informations stockées dans ce cookie. Toutes les informations
            collectées par nos « cookies » de performance ne seront utilisées
            que pour suivre le volume, le type et la configuration du trafic
            utilisant ce site, pour en développer la conception et l'agencement
            et à d'autres fins administratives et de planification et plus
            généralement pour améliorer le service que nous vous offrons. Pour
            plus d'informations sur l'utilisation, la gestion et la suppression
            des « cookies », pour tout type de navigateur, nous vous invitons à
            consulter le lien suivant :
            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
          </p>
          <h4 className='text-title'>10. Prestataire technique:</h4>
          <p>
            Les prestations techniques sont assurées par Ionos.fr, société
            anonyme à Directoire et Conseil de Surveillance, dont le siège
            social est situé : 7 place de la Gare - BP 70109 57201 Sarreguemines
            Cedex France www.ionos.fr Escale Océane, 2 Impasse de la scierie,
            40140 Soustons
          </p>
        </div>
      </section>
    </Fragment>
  );
};

export default TextMention;
