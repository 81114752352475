import React, { Fragment } from 'react';
import ObjetCom from './ObjetCom';

const MainCom = () => {
  return (
    <Fragment>
      <ObjetCom></ObjetCom>
    </Fragment>
  );
};

export default MainCom;
