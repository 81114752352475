import React, { Fragment } from 'react';
import ContactForm from './ContactForm';

const MainContact = () => {
  return (
    <Fragment>
      <ContactForm></ContactForm>
    </Fragment>
  );
};

export default MainContact;
