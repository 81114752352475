import React from 'react';
import { Link } from 'react-router-dom';

const TextBox = () => {
  return (
    <div className='text-box'>
      <h2 className='product-title'>Qui sommes-nous?</h2>
      <p className='text-home'>
        Nous sommes à votre écoute pour satisfaire vos besoins. Travaillant en
        collaboration avec différents fournisseurs en France. Nous vous
        proposons un large choix d’objet publicitaire et de textile. <br></br>
        <br />
        Que vous soyez particuliers, une association ou une entreprise, nous
        vous accompagnons dans votre projet. <br></br>
        <br /> Depuis plus de 20 ans dans le milieu de la communication et de la
        personnalisation, Jean- Guillaume à travailler à l’étranger pour de
        grande marque comme Canterbury of New Zélande, Adidas, Nike, Under Armor
        dans le milieu sportif pour le marquage textile. Il a aussi collaboré
        avec Vivienne Westwood, Child of the Jago, Lewis leather, le groupe
        Arcade Fire et bien d’autre durant son poste de graphiste et studio
        manager à Londres. <br></br>
        <br />
        Il est revenu d’Angleterre avec toute son expérience et a ouvert son
        entreprise en Essonne ou il a collaboré avec une clientèle plus diverse
        en passant de la signalétique à la personnalisation textile. <br></br>
        <br />
        <div>
          Maintenant vivant à Soustons, il propose ses services en&nbsp;
          <Link
            to='/perso-textile'
            className='home-link'
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            personnalisation textile
          </Link>
          ,&nbsp;
          <Link
            to='/objet-pub'
            className='home-link'
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            objet publicitaire
          </Link>
          ,&nbsp;
          <Link
            to='/signaletique'
            className='home-link'
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            signalétique
          </Link>
          &nbsp;et&nbsp;
          <Link
            to='/communication'
            className='home-link'
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            communication visuelle
          </Link>
          &nbsp;.
        </div>
        <br></br>
        <br />
        Il vous accompagnera et vous conseillera jusqu’au bout de votre projet.
      </p>
    </div>
  );
};

export default TextBox;
