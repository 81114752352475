import React, { Fragment } from 'react';
import TextMention from './TextMention';

const MainMention = () => {
  return (
    <Fragment>
      <TextMention></TextMention>
    </Fragment>
  );
};

export default MainMention;
