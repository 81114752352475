import React, { useEffect } from 'react';
import Photo1 from '../../../assets/gum/textile-broderie-gum.jpeg';
import Photo2 from '../../../assets/gum/textile-broderie-bonnet.jpeg';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const TextRightTextileSecond = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <div
        className='product-split'
        data-aos='fade-left'
        data-aos-duration='1500'
        id='product-split-text-right'
      >
        <div className='product-div-photo-left'>
          <img src={Photo1} alt='' className='product-photo-left' />
          {/* <img
            src={Photo2}
            alt=''
            className='product-photo-left second-photo'
          /> */}
        </div>
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Marquage flex </span>
            </h3> */}
          </div>
          <h2 className='product-title paragraph'>Broderie textile</h2>
          <p className='product-text'>
            Un marquage haut de gamme, solide et plébiscité pour son aspect
            chic.
            <br></br>
            <br></br>
            La broderie textile est à la foi la plus robuste et la plus chic des
            techniques de marquage pour un vêtement personnalisé.
            Particulièrement adaptée pour les tissus épais comme le sweat, les
            casquettes ou les polos, elle est aussi très utilisée pour les
            vêtements d’extérieur.
            <br></br>
            <br></br>
            La broderie sur tissu est plus onéreuse et plus exigeante que les
            autres techniques de marquage. Un marquage haut de gamme, solide et
            plébiscité pour son aspect chic.
          </p>
          <RegularButton></RegularButton>
        </div>
      </div>
    </section>
  );
};
