import React from 'react';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import { VscCallOutgoing, VscMail } from 'react-icons/vsc';

export const links = [
  {
    id: 1,
    url: '/',
    text: 'Accueil',
  },
  {
    id: 2,
    url: '/objet-pub',
    text: 'Objet publicitaire',
  },
  {
    id: 3,
    url: '/perso-textile',
    text: 'Pers. textile',
  },
  {
    id: 4,
    url: '/communication',
    text: 'Communication',
  },
  {
    id: 5,
    url: '/signaletique',
    text: 'Signalétique',
  },
  {
    id: 6,
    url: '/contact',
    text: 'Contact',
  },
  {
    id: 7,
    url: '/mentions-legales',
    text: 'Mentions légales',
  },
];

export const social = [
  {
    id: 1,
    url: 'https://www.facebook.com/profile.php?id=100083785490452',
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: 'https://www.twitter.com',
    icon: <FaLinkedin />,
  },
];

export const contacts = [
  // {
  //   id: 'adresse',
  //   text: 'blabla',
  //   icon: <BsGeoAltFill />,
  // },
  {
    id: 2,
    text: '06 62 14 77 22',
    icon: <VscCallOutgoing />,
  },
  {
    id: 3,
    text: 'swellpub@gmail.com',
    icon: <VscMail />,
  },
];

export const pub = [
  {
    image: './photo/gum/gum-tshirt.jpg',
    caption: 'T-shirt',
  },
  {
    image: './photo/gum/gum-cap.jpg',
    caption: 'Cap',
  },
];

export const textileMain = [
  {
    image: './photo/textileMain/textile-front.jpeg',
    caption: '',
  },
  {
    image: './photo/textileMain/textile-front-2.png',
    caption: '',
  },
  {
    image: './photo/textileMain/textile-front-3.jpeg',
    caption: '',
  },

  {
    image: './photo/textileMain/textile-front-4.jpeg',
    caption: '',
  },
  {
    image: './photo/textileMain/textile-front-5.jpeg',
    caption: '',
  },
];

export const com = [
  {
    image: './photo/com/communication-visuelle-burger.jpeg',
    caption: 'Burger',
  },
  {
    image: './photo/com/communication-visuelle-menu.jpeg',
    caption: 'Menu burger',
  },
  {
    image: './photo/com/communication-visuelle-parlamela.jpg',
    caption: 'Parlamela',
  },
];

export const signaletique = [
  {
    image: './photo/signaletique/signaletique-bar.jpeg',
    caption: 'Bar',
  },
  {
    image: './photo/signaletique/signaletique-cook.jpeg',
    caption: 'Pancarte',
  },
  {
    image: './photo/signaletique/signaletique-van.jpeg',
    caption: 'Van',
  },
];

export const broderie = [
  {
    image: './photo/textile/textile-broderie-bonnet.jpeg',
    caption: 'Bonnet',
  },
  {
    image: './photo/textile/textile-broderie-casjpeg',
    caption: 'Casquette',
  },
  {
    image: './photo/textile/textile-broderie-chapeau.jpeg',
    caption: 'Chapeau',
  },
  {
    image: './photo/textile/textile-broderie-gum.jpeg',
    caption: 'Gum',
  },
];

export const objetPublicitaire = [
  {
    image: './photo/pub/objet-pub-snowboard.jpeg',
    caption: 'Snowboard Gum',
  },
  {
    image: './photo/pub/objet-pub-snowboard-2.jpeg',
    caption: 'Snowboard Gum',
  },
];
