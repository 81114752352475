import React, { useEffect } from 'react';
import { com } from '../../../data/data';
import { Carousel } from 'react-carousel-minimal';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const ObjetCom = () => {
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <h2 className='product-title'>
        " Nous vous accompagnons du début à la fin pour la création de votre
        projet. "
      </h2>
      <div
        className='product-split'
        data-aos='zoom-in-down'
        data-aos-duration='1500'
      >
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Nos produits </span>
            </h3> */}
          </div>

          <p className='product-text'>
            De la création de logo, d'une marque de vêtement ou d’un site
            internet, notre service graphique vous suit et vous conseille dans
            votre projet.
          </p>

          <div>
            <RegularButton></RegularButton>
          </div>
        </div>
        <div className='product-carousel'>
          <div>
            <Carousel
              data={com}
              time={8000}
              width='850px'
              height='500px'
              captionStyle={captionStyle}
              radius='10px'
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition='bottom'
              automatic={true}
              dots={true}
              pauseIconColor='white'
              pauseIconSize='40px'
              slideBackgroundColor='darkgrey'
              slideImageFit='cover'
              style={{
                textAlign: 'center',
                maxWidth: '850px',
                maxHeight: '500px',
                margin: '40px auto',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ObjetCom;
