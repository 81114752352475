import React from 'react';
import Logo from '../../assets/logo/logo.png';
import { links, contacts, social } from '../../data/data';
import { HiArrowRight } from 'react-icons/hi';

const Footer = () => {
  return (
    <section>
      <div className='logo-top-footer'>
        <img src={Logo} alt='logo' className='logo' />
      </div>
      <div className='footer'>
        <div className='logo-swell-footer'>
          <img src={Logo} alt='logo' className='logo' />
          <p className='title-footer'>La qualité à votre service!</p>
        </div>
        <div className='contact-footer'>
          <div className='title-footer'>Nous contacter</div>
          <ul>
            {contacts.map((contact) => {
              const { id, text, icon } = contact;
              return (
                <li key={id}>
                  <div className='contact-text'>
                    <div className='icon-span'>
                      <div className='adjust-span'>{icon}</div>
                    </div>
                    <a className='contact-details'>{text}</a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='company-footer'>
          <div className='title-footer'>Nos produits</div>
          <div className='links-footer'>
            <ul className='links-map-footer'>
              {links.map((link) => {
                const { id, url, text } = link;
                if (id === 1 || id === 6 || id === 7) {
                  return null;
                } else {
                  return (
                    <li key={id} className='company-li'>
                      <a href={url} className='links-hover-footer'>
                        {text}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        <div className='follow-footer'>
          <div>
            <p className='title-footer'>En savoir plus</p>
            <ul className='savoir-map-footer'>
              {links.map((link) => {
                const { id, url, text } = link;
                if (id === 1 || id === 2 || id === 3 || id === 4 || id === 5) {
                  return null;
                } else {
                  return (
                    <li key={id} className='savoir-li'>
                      <a href={url} className='savoir-hover-footer'>
                        {text}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div>
            <p className='title-footer'>Suivez nous</p>
            <ul className='logo-footer'>
              {social.map((socials) => {
                const { id, url, icon } = socials;
                return (
                  <li key={id}>
                    <a href={url} className='details-footer'>
                      {icon}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className='border-footer'>
        <p>Swell Publicité @ 2022</p>
      </div>
    </section>
  );
};

export default Footer;
