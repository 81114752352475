import React, { useEffect } from 'react';
import Photo from '../../../assets/obar-basque.jpeg';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const TextLeftTextileSecond = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <div
        className='product-split'
        data-aos='fade-left'
        data-aos-duration='1500'
        id='product-split-text-left'
      >
        <div className='product-div-photo-left'>
          <img src={Photo} alt='' className='product-photo-left' />
        </div>
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Marquage flex </span>
            </h3> */}
          </div>
          <h2 className='product-title paragraph'>Sérigraphie textile</h2>
          <p className='product-text'>
            Une méthode d’impression ancestrale, idéale pour les grandes séries.
            <br></br>
            <br></br>
            La sérigraphie textile consiste à appliquer successivement chaque
            encre de couleur qui compose le visuel sur le tissu tendu. Elle
            offre une excellente qualité de marquage avec un rendu détaillé et
            fidèle au visuel d’origine et des couleurs éclatantes.
          </p>
          <RegularButton></RegularButton>
        </div>
      </div>
    </section>
  );
};
