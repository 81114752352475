import React from 'react'
import Photo from '../parallax/HomePara';
import TextBox from '../parallax/TextBox';

const Home = () => {
  return (
    <div>
      <Photo></Photo>
      <TextBox></TextBox>
    </div>
  );
}

export default Home
