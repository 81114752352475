import React, { useEffect } from 'react';
import Photo1 from '../../../assets/flex-quadriflex/flex-découpe-swell-publicité.jpg';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const TextRightTextile = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <div
        className='product-split'
        data-aos='fade-left'
        data-aos-duration='1500'
        id='product-split-text-right'
      >
        <div className='product-div-photo-left'>
          <img src={Photo1} alt='' className='product-photo-left' />
          {/* <img
            src={Photo2}
            alt=''
            className='product-photo-left second-photo'
          /> */}
        </div>
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Marquage flex </span>
            </h3> */}
          </div>
          <h2 className='product-title paragraph'>
            Marquage flex et quadriflex
          </h2>
          <p className='product-text'>
            Le marquage flex est une technique de personnalisation textile
            consistant à presser un vinyle thermocollant sur le tissu à décorer.
            <br></br>
            <br></br>
            Il existe 2 types de marquage flex : le flex découpé (le motif est
            directement découpé dans le vinyle de la couleur choisie), le flex
            imprimé ou quadriflex (l’image souhaitée est imprimée sur un vinyle
            blanc avant d’être découpée et pressée).
            <br></br>
            <br></br>
            Cette technique de marquage, parfois aussi appelée flocage, permet
            de multiples effets grâce au grand choix de vinyles (couleurs,
            paillettes, fluo, texture velours...) existant sur le marché.
          </p>
          <RegularButton></RegularButton>
        </div>
      </div>
    </section>
  );
};
