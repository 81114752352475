import React, { Fragment } from 'react';
import ObjetTextile from './ObjetTextile';
import { TextRightTextile } from './TextRightTextile';
import { TextLeftTextile } from './TextLeftTextile';
import { TextRightTextileSecond } from './TextRightTextileSecond';
import { TextLeftTextileSecond } from './TextLeftTextileSecond';

const MainTextile = () => {
  return (
    <Fragment>
      <ObjetTextile></ObjetTextile>
      <TextRightTextile></TextRightTextile>
      <TextLeftTextile></TextLeftTextile>
      <TextRightTextileSecond></TextRightTextileSecond>
      <TextLeftTextileSecond></TextLeftTextileSecond>
    </Fragment>
  );
};

export default MainTextile;
