import React, { Fragment } from 'react';
import ObjetPub from './ObjetPub';

const MainPub = () => {
  return (
    <Fragment>
      <ObjetPub></ObjetPub>
    </Fragment>
  );
};

export default MainPub;
