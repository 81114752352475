import React, { useEffect } from 'react';
import { pub, textileMain } from '../../../data/data';
import { Carousel } from 'react-carousel-minimal';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ObjetTextile = () => {
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <h2 className='product-title'>" Vous équiper est notre priorité "</h2>
      <div
        className='product-split'
        data-aos='zoom-in-down'
        data-aos-duration='1500'
      >
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Nos produits </span>
            </h3> */}
          </div>

          <p className='product-text'>
            Petite ou grosse production, particulier, professionnel ou club
            sportif nous vous conseillerons pour le textile et la production.
            Nous travaillons avec différents fournisseurs pour répondre à vos
            besoins. Association ou club sportif, nous pouvons vous proposer
            différentes marques de sport.
          </p>

          <div>
            <RegularButton></RegularButton>
          </div>
        </div>
        <div className='product-carousel'>
          <div
            style={{
              padding: '0 20px',
            }}
          >
            <Carousel
              data={textileMain}
              time={8000}
              width='850px'
              height='500px'
              captionStyle={captionStyle}
              radius='10px'
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition='bottom'
              automatic={true}
              dots={true}
              pauseIconColor='white'
              pauseIconSize='40px'
              slideBackgroundColor='darkgrey'
              slideImageFit='cover'
              style={{
                textAlign: 'center',
                maxWidth: '850px',
                maxHeight: '500px',
                margin: '40px auto',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ObjetTextile;
