import React, { useEffect } from 'react';
import { objetPublicitaire } from '../../../data/data';
import { Carousel } from 'react-carousel-minimal';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ObjetPub = () => {
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <h2 className='product-title'>
        " Spécialiste de l&#39;objet publicitaire personnalisé pour entreprise
        et association "
      </h2>
      <div
        className='product-split'
        data-aos='zoom-in-down'
        data-aos-duration='1500'
      >
        <div className='product-presentation'>
          <p className='product-text'>
            Swell Publicité s’engage à offrir un service global à ses clients au
            meilleur prix. Une vision globale qui repose sur une large gamme de
            goodies publicitaires touchant de nombreuses thématiques et secteurs
            d’activité, pouvant être personnalisés ou non d’un marquage.
          </p>
          <p className='product-text'>
            Plébiscités grâce à leurs taux de mémorisation, de conservation et
            de satisfaction, les objets publicitaires sont un support
            incontournable de communication. Idéales pour toucher un large
            public lors d’un événement ou une action, les goodies d’entreprise
            sont parfaits pour fidéliser ou remercier ses clients.
          </p>
          <div>
            <RegularButton></RegularButton>
          </div>
        </div>
        <div className='product-carousel'>
          <div>
            <Carousel
              data={objetPublicitaire}
              time={8000}
              width='850px'
              height='500px'
              captionStyle={captionStyle}
              radius='10px'
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition='bottom'
              automatic={true}
              dots={true}
              pauseIconColor='white'
              pauseIconSize='40px'
              slideBackgroundColor='darkgrey'
              slideImageFit='cover'
              style={{
                textAlign: 'center',
                maxWidth: '1050px',
                maxHeight: '500px',
                // margin: '40px auto',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ObjetPub;
