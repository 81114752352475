import React, { Fragment } from 'react';
import ObjetSignal from './ObjetSignal';

const MainSignal = () => {
  return (
    <Fragment>
      <ObjetSignal></ObjetSignal>
    </Fragment>
  );
};

export default MainSignal;
