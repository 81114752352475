import emailjs from 'emailjs-com';
import React, { useRef, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Photo from '../../../assets/gum/gum-cap.jpg';
import classNames from 'classnames';

const USER_REGEX = /^[A-z][A-z0-9-_]{1,35}$/;
const USER_MESSAGE = /.{1,500}$/;
const USER_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const ContactForm = () => {
  const form = useRef();
  const userRef = useRef();
  const errRef = useRef();
  const [focus, setFocus] = useState(false);

  const [name, setName] = useState('');
  const [validName, setValidName] = useState(false);
  const [prenom, setPrenom] = useState('');
  const [validPrenom, setValidPrenom] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [tel, setTel] = useState('');
  const [sujet, setSujet] = useState('');
  const [validSujet, setValidSujet] = useState(false);
  const [message, setMessage] = useState('');
  const [validMessage, setValidMessage] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  // const customStyle = focus ? styles.input : styles.inputFocus;

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(name));
    setValidPrenom(USER_REGEX.test(prenom));
    setValidEmail(USER_EMAIL.test(email));
    setValidSujet(USER_MESSAGE.test(sujet));
    setValidMessage(USER_MESSAGE.test(message));
  }, [name, prenom, email, sujet, message]);

  useEffect(() => {
    setErrMsg('');
  }, [name, prenom, email, sujet, message]);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Message envoyé!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const nameClasses = classNames({
    'form-input': true,
    'form-input-success': validName && name && !focus,
    'form-input-error': !validName && name && !focus,
  });

  const prenomClasses = classNames({
    'form-input': true,
    'form-input-success': validPrenom && prenom && !focus,
    'form-input-error': !validPrenom && prenom && !focus,
  });

  const emailClasses = classNames({
    'form-input': true,
    'form-input-success': validEmail && email && !focus,
    'form-input-error': !validEmail && email && !focus,
  });

  const sujetClasses = classNames({
    'form-input': true,
    'form-input-success': validSujet && sujet && !focus,
    'form-input-error': !validSujet && sujet && !focus,
  });

  const messageClasses = classNames({
    'form-input': true,
    'form-input-success': validMessage && message && !focus,
    'form-input-error': !validMessage && message && !focus,
  });

  const sendEmail = (e) => {
    e.preventDefault();

    const v1 = USER_REGEX.test(name);
    const v2 = USER_REGEX.test(prenom);
    const v3 = USER_EMAIL.test(email);
    const v4 = USER_MESSAGE.test(sujet);
    const v5 = USER_MESSAGE.test(message);

    if (!v1 || !v2 || !v3 || !v4 || !v5) {
      setErrMsg('Invalid Entry');
      return;
    }
    emailjs
      .sendForm(
        'service_p0483nh',
        'template_j301cds',
        form.current,
        'VuMkXjj49In22TufO'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    toastifySuccess();
  };

  return (
    <section className='product-background'>
      <h2 className='product-title'>" Nous sommes à votre écoute "</h2>
      <div className='product-split'>
        <div className='product-div-photo-left'>
          <img src={Photo} alt='' className='product-photo-left' />
        </div>
        <div>
          <form ref={form} onSubmit={sendEmail} className='form'>
            <div className='form-name-direction'>
              <div className='form-name'>
                <label htmlFor='name'>Nom</label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className={nameClasses}
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  aria-invalid={validName ? 'false' : 'true'}
                  aria-describedby='uidnote1'
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />

                <p
                  id='uidnote1'
                  className={
                    !focus && name && !validName ? 'invalid' : 'offscreen'
                  }
                >
                  Veuillez écrire votre nom.
                </p>
              </div>

              <div className='form-name'>
                <label htmlFor='prenom'>Prénom</label>
                <input
                  type='text'
                  id='prenom'
                  name='prenom'
                  className={prenomClasses}
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setPrenom(e.target.value)}
                  value={prenom}
                  required
                  aria-invalid={validPrenom ? 'false' : 'true'}
                  aria-describedby='uidnote2'
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />

                <p
                  id='uidnote2'
                  className={
                    !focus && prenom && !validPrenom ? 'invalid' : 'offscreen'
                  }
                >
                  Veuillez écrire votre prénom.
                </p>
              </div>
            </div>

            <div className='form-name-direction'>
              <div className='form-name'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className={emailClasses}
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-invalid={validEmail ? 'false' : 'true'}
                  aria-describedby='uidnote3'
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
                <p
                  id='uidnote3'
                  className={
                    !focus && email && !validEmail ? 'invalid' : 'offscreen'
                  }
                >
                  Votre addresse email est incorrecte.
                </p>
              </div>

              <div className='form-name'>
                <label htmlFor='tel'>Téléphone(facultatif)</label>
                <input
                  type='tel'
                  id='tel'
                  name='tel'
                  className='form-input'
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setTel(e.target.value)}
                  value={tel}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
              </div>
            </div>

            <label htmlFor='sujet'>Sujet de ma demande</label>
            <input
              type='text'
              id='sujet'
              name='sujet'
              className={sujetClasses}
              ref={userRef}
              autoComplete='off'
              onChange={(e) => setSujet(e.target.value)}
              value={sujet}
              required
              aria-invalid={validSujet ? 'false' : 'true'}
              aria-describedby='uidnote4'
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />

            <p
              id='uidnote4'
              className={
                !focus && sujet && !validSujet ? 'invalid' : 'offscreen'
              }
            >
              Veuillez préciser le sujet de votre demande.
            </p>
            <label htmlFor='message'>Message</label>
            <textarea
              id='message'
              name='message'
              ref={userRef}
              value={message}
              className={messageClasses}
              autoComplete='off'
              onChange={(e) => setMessage(e.target.value)}
              required
              aria-invalid={validMessage ? 'false' : 'true'}
              aria-describedby='uidnote5'
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />

            <p
              id='uidnote5'
              className={
                !focus && message && !validMessage ? 'invalid' : 'offscreen'
              }
            >
              Veuillez préciser votre demande.
            </p>
            <input
              disabled={
                !validName ||
                !validPrenom ||
                !validEmail ||
                !validSujet ||
                !validMessage
                  ? true
                  : false
              }
              className='form-button'
              type='submit'
              value='Je fais ma demande'
            />
          </form>
        </div>

        <ToastContainer></ToastContainer>
      </div>
    </section>
  );
};

// const styles = StyleSheet.create({
//   inputFocus: {
//     borderColor: '#FFFF00',
//     height: 40,
//     margin: 12,
//     borderWidth: 1,
//     padding: 10,
//   },
//   input: {
//     borderColor: '#FFFF00',
//     height: 40,
//     margin: 12,
//     borderWidth: 1,
//     padding: 10,
//   },
// });

export default ContactForm;
