import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Watch the Button tutorial
// http://react.school/ui/button

// Free React training
// http://react.school/join

// Free Material-UI template
// http://react.school/material-ui/templates

const theme = {
  blue: {
    default: '#3f51b5',
    hover: '#283593',
  },
  pink: {
    default: '#e91e63',
    hover: '#ad1457',
  },
};

const Button = styled.button`
  border-radius: 100px 100px 100px 0px;
  background-color: #e8c331;
  border: none;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  width: 220px;
  transition: all 0.5s;
  cursor: pointer;

  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);

  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;

  &:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 16px;
    right: -30px;
    transition: 0.5s;
  }

  &:hover {
    padding-right: 24px;
    padding-left: 8px;
  }

  &:hover:after {
    opacity: 1;
    right: 10px;
  }
`;

Button.defaultProps = {
  theme: 'blue',
};

function clickMe() {
  alert('You clicked me!');
}

const ButtonToggle = styled(Button)`
  opacity: 0.7;
  ${({ active }) =>
    active &&
    `
    opacity: 1; 
  `}
`;

const Tab = styled.button`
  padding: 10px 30px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  transition: ease border-bottom 250ms;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;

function TabGroup() {
  const [active, setActive] = useState(types[0]);
  return (
    <div>
      <div>
        {types.map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </div>
      <p />
      <p> Your payment selection: {active} </p>
    </div>
  );
}

const types = ['Cash', 'Credit Card', 'Bitcoin'];

function ToggleGroup() {
  const [active, setActive] = useState(types[0]);
  return (
    <div>
      {types.map((type) => (
        <ButtonToggle active={active === type} onClick={() => setActive(type)}>
          {type}
        </ButtonToggle>
      ))}
    </div>
  );
}

export default function Regular() {
  return (
    <Fragment>
      {/* <div>
        <Button onClick={clickMe}>Button</Button>
      </div>
      <div>
        <Button theme='pink' onClick={clickMe}>
          Pink theme
        </Button>
      </div> */}
      {/* <div>
        <Button disabled onClick={clickMe}>
          Disabled
        </Button>
      </div> */}
      <Link to='/contact'>
        <Button>Je me renseigne</Button>
      </Link>
      {/* <ToggleGroup />
      <TabGroup /> */}
    </Fragment>
  );
}
