import React from 'react';
import PhotoHome from '../../assets/swell-pub.png';
import { Parallax } from 'react-parallax';

const HomePara = () => {
  return (
    <div>
      <Parallax
        bgImage={PhotoHome}
        bgImageAlt='textile publicité'
        strength={500}
        className='image'
      >
        <div className='content'>
          <h1 className='text-para'>" Bienvenue chez Swell Publicité "</h1>
        </div>
      </Parallax>
    </div>
  );
};

export default HomePara;
