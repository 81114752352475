import React, { useEffect } from 'react';
import Photo1 from '../../../assets/dtg/dtg-tshirt.jpeg';
import Photo2 from '../../../assets/dtg/dtg-tshirt2.jpeg';
import RegularButton from '../../buttons/Regular';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const TextLeftTextile = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className='product-background'>
      <div
        className='product-split'
        data-aos='fade-left'
        data-aos-duration='1500'
        id='product-split-text-left'
      >
        <div className='product-div-photo-left'>
          <img src={Photo1} alt='' className='product-photo-left' />
          {/* <img
            src={Photo2}
            alt=''
            className='product-photo-left second-photo'
          /> */}
        </div>
        <div className='product-presentation'>
          <div className='product'>
            {/* <h3>
              <span className='product-span'>Marquage flex </span>
            </h3> */}
          </div>
          <h2 className='product-title paragraph'>
            Impression numérique directe (DTG)
          </h2>
          <p className='product-text'>
            L’impression aux couleurs illimitées, à même le textile : simple,
            efficace et ultra détaillée.
            <br></br>
            <br></br>
            L'impression numérique directe, « Direct To Garnment » en anglais
            (DTG), est la technique plébiscitée pour l&#39;impression de photos
            ou de motifs complexes, avec des dégradés de couleurs. Elle consiste
            en l&#39;impression directe du visuel sur le tissu tendu et est
            utilisable sur toutes les couleurs de textiles (foncées ou
            claires). 
            <br></br>
            <br></br>
            Solution parfaite pour les vêtements personnalisés, vous pouvez
            choisir l’impression numérique directe pour la réalisation de vos
            t-shirts, tabliers ou polos d’entreprise à votre image ou pour la
            réalisation de produits promotionnels ou de marque.
          </p>
          <RegularButton></RegularButton>
        </div>
      </div>
    </section>
  );
};
