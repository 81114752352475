import './App.css';
import Navbar from './components/main/Navbar';
import Footer from './components/main/Footer';
import Home from './components/pages/Home';
import React from 'react';

import { Routes, Route, Router } from 'react-router-dom';
import MainPub from './components/pages/Publicitaire/MainPub';
import MainTextile from './components/pages/Textile/MainTextile';
import MainCom from './components/pages/Communication/MainCom';
import MainSignal from './components/pages/Signaletique/MainSignal';
import MainContact from './components/pages/Contact/MainContact';
import MainMention from './components/pages/Mention/MainMention';

function App() {
  return (
   
      <div>
        <Navbar></Navbar>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/objet-pub' element={<MainPub />} />
          <Route path='/perso-textile' element={<MainTextile />} />
          <Route path='/communication' element={<MainCom />} />
          <Route path='/signaletique' element={<MainSignal />} />
          <Route path='/contact' element={<MainContact />} />
          <Route path='/mentions-legales' element={<MainMention />} />
        </Routes>
        <Footer></Footer>
      </div>
  
  );
}

export default App;
